<template>
    <header class="dmrc-header">          
      <!-- Nav items -->
      <nav class="primaryNavigation">
        <desktop-menu-item :ClassName="['companyLogo', 'controllerCompany']" 
          :Name="'Corporate'" :CurrentMenuItem="currentMenuItem" >
          <svg slot="menuItemIcon" style="width:24px;height:24px;" version="1.1" id="Layer_1_a" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 200 200" xml:space="preserve"><g><g><path class="st0" fill="#acacac" d="M115.1,49.3H98.8v18.6h-15c-9.2,0-16.3,4.5-16.3,10.4v55.6c0,5.8,7.1,10.4,16.3,10.4h31.9v-95H115.1zM98.8,133.2H84.4V79h14.3L98.8,133.2L98.8,133.2z"></path></g><g><path class="st0" fill="#acacac" d="M102.9,8h-2.3v20h-2.9C44.4,28,1.1,63.8,1.1,107.8s43.5,79.9,97,79.9h2.3l0.1-20h2.2
                c53.1-0.2,96.2-36,96.2-79.9C198.9,44,155.8,8.1,102.9,8 M100.5,165L100.5,165c-0.5,0-1.1,0-1.6,0c-43.6-1.7-78-31.2-78-67.1
                c0-36.2,34.7-65.6,78.2-67.2c0.5,0,1.1,0,1.5,0l0,0c44.2,0.9,79.6,30.6,79.6,67.2C180.3,134.4,144.7,164.2,100.5,165"></path></g></g>
          </svg>
          <svg slot="menuContentIcon" style="width:24px;height:24px; fill:#ffffff" version="1.1" id="Layer_1_n" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 200 200" xml:space="preserve"><g><g><path class="st0" fill="#ffffff" d="M115.1,49.3H98.8v18.6h-15c-9.2,0-16.3,4.5-16.3,10.4v55.6c0,5.8,7.1,10.4,16.3,10.4h31.9v-95H115.1zM98.8,133.2H84.4V79h14.3L98.8,133.2L98.8,133.2z"></path></g><g><path class="st0" fill="#ffffff" d="M102.9,8h-2.3v20h-2.9C44.4,28,1.1,63.8,1.1,107.8s43.5,79.9,97,79.9h2.3l0.1-20h2.2
                c53.1-0.2,96.2-36,96.2-79.9C198.9,44,155.8,8.1,102.9,8 M100.5,165L100.5,165c-0.5,0-1.1,0-1.6,0c-43.6-1.7-78-31.2-78-67.1
                c0-36.2,34.7-65.6,78.2-67.2c0.5,0,1.1,0,1.5,0l0,0c44.2,0.9,79.6,30.6,79.6,67.2C180.3,134.4,144.7,164.2,100.5,165"></path></g></g>
          </svg>  
        </desktop-menu-item>
        <desktop-menu-item 
          :ClassName="['myAppsDMRC', 'controllerMyApps']" 
          :Name="'Modules'"  :CurrentMenuItem="currentMenuItem">
            <span slot="menuItemIcon" class="fas fa-th"></span>
            <span slot="menuContentIcon" class="fas fa-th"></span>
        </desktop-menu-item>
        <desktop-menu-item 
          :ClassName="['MoreDMRC', 'controllerSupport']" 
          :Name="'Support'" :CurrentMenuItem="currentMenuItem">
            <span slot="menuItemIcon" class="fa fa-life-ring"></span>
            <span slot="menuContentIcon" class="fa fa-life-ring"></span>
        </desktop-menu-item>
      </nav>
      <div v-if="isPopup" class="outside" v-on:click="away()"></div>
    </header> <!-- End Desktop nav -->
</template>

<script>
import DesktopMenuItem from './DesktopMenuItem.vue'

export default {
  name: 'DesktopMenu',
  components: {
    DesktopMenuItem
  },
  data: function() {
    return {
      currentMenuItem: null,
      isPopup: false
    }
  },
  created: function () {
    this.$on('menuItemClicked', (data) => {
      if (this.currentMenuItem == data) {
        this.currentMenuItem = null; // Already open, close it
        this.isPopup = false;
      }
      else {
        this.currentMenuItem = data;
        this.isPopup = true;
      }
    })
  },  
  methods: {
    away() {
      this.isPopup = false;
      this.currentMenuItem = null;
    }
  }
}
</script>

<style scoped>
.primaryNavigation a.companyLogo svg:hover {
  color: white;
}

.outside {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 400px;
}
</style>