import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false;

Vue.prototype.$accessToken = getAccessToken();
Vue.prototype.$applicationsData = getApplicationsData();

new Vue({
  render: h => h(App),
}).$mount('#appMenu')

function getApplicationsData() {
  let applicationsData = null;
    let appParentElement = document.getElementById('appMenu').parentElement;
  // Check simple attribute attach
  if (appParentElement.hasAttribute('applications')) {
    applicationsData = appParentElement.getAttribute('applications');
  }
  // Check dataset (e.g. data-token)
  else if (appParentElement.dataset && appParentElement.dataset.applications) {
    applicationsData = appParentElement.dataset.applications;
  }
  // Check local storage 
  else if (localStorage.getItem('applications')){
    applicationsData = localStorage.getItem('applications');
  }
  // Check if jQuery attached
  else {
    for(var propertyName in appParentElement) {
       if (propertyName.startsWith('jQuery')){
         var jQueryProp = appParentElement[propertyName];
         if (jQueryProp && jQueryProp.applications !== undefined) {
          applicationsData = jQueryProp.applications
         }
       }
    }
  }
  return applicationsData;
}

function getAccessToken() {
  let accessToken = null;
    let appParentElement = document.getElementById('appMenu').parentElement;
  // Check simple attribute attach
  if (appParentElement.hasAttribute('token')) {
    accessToken = appParentElement.getAttribute('token');
  }
  // Check dataset (e.g. data-token)
  else if (appParentElement.dataset && appParentElement.dataset.token) {
    accessToken = appParentElement.dataset.token;
  }
  // Check local storage 
  else if (localStorage.getItem('token')){
    accessToken = localStorage.getItem('token');
  }
  // Check if jQuery attached
  else {
    for(var propertyName in appParentElement) {
       if (propertyName.startsWith('jQuery')){
         var jQueryProp = appParentElement[propertyName];
         if (jQueryProp && jQueryProp.token) {
          accessToken = jQueryProp.token
         }
       }
    }
  }
  return accessToken;
}
