<template>
  <div id="appMenu" class="allNav">
    <mobile-menu/>    
    <desktop-menu/>
  </div>
</template>

<script>
import DesktopMenu from './components/DesktopMenu.vue'
import MobileMenu from './components/MobileMenu.vue'

export default {
  name: 'AppMenu',
  components: {
    DesktopMenu,
    MobileMenu
  },
  mounted: function () {
      let appParentElement = document.getElementById('appMenu').parentElement;
    appParentElement.style.display = 'block';
  }
}
</script>

<style>

</style>
