import linksData from "./links.json";
import axios from 'axios';

var menuItemsMixin = {
    data: function () {
        return {
          modules: [],
          modulesLoading: false,
          errorFetchingModules: false
        };
      },
    methods: {    
        fetchMenuItems: function(menuRefName) {
            // Prevent double click
            if (this.modulesLoading === true) return;

            this.modules = [];
            
            this.modulesLoading = true;

            if (menuRefName === 'Modules') {
                this.fetchUserAppModules();
                return;
            }

            this.modules = linksData.filter((data) => data.name === menuRefName)[0].links;
            this.modulesLoading = false;
        },
        fetchUserAppModules: function() {
            const config = {
                headers: {
                    Authorization: 'Bearer ' + this.$accessToken,
                    'Content-Type': 'application/json'
                }
            }

            const bodyContent = {
                profiles : ["applications"]
            }
                
            const requestUrl = 'https://signin-dev.digimarc.net/core/connect/userinfo';

            let appModule = linksData.filter((data) => data.name === 'Modules');
            let appModuleLinks = appModule[0].links;

            let unrestrictedAppLinks = appModuleLinks.filter((appModuleLink) => !appModuleLink.appID);

            this.modules.push.apply(this.modules, unrestrictedAppLinks);

            if (this.$applicationsData || this.$applicationsData === '') {
                let applications = this.$applicationsData;  
                var restrictedMenuItems = appModuleLinks.filter((appModuleLink) => applications.indexOf(appModuleLink.appID) > -1)
                this.modules.push.apply(this.modules, restrictedMenuItems);    
                this.modulesLoading = false; 
                return;
            }
            
            axios
                .post(
                    requestUrl,
                    bodyContent,
                    config
                )
                .then(response => {
                    let applications = response.data.applications;  
                    var restrictedMenuItems = appModuleLinks.filter((appModuleLink) => applications.indexOf(appModuleLink.appID) > -1)
                    this.modules.push.apply(this.modules, restrictedMenuItems);       
                }).catch((error) => {
                    if( error ){
                        console.log('User menu item fetch status: ' + error.response.status);                
                    } 
                    this.errorFetchingModules = true;
                }).finally(() => {
                    this.modulesLoading = false;   
                });
        }
    }
};

export default menuItemsMixin