<template>

  <a v-bind:class="navClass" v-on:click="menuClick">
    <span class="fa-nav-icon">
        <slot name="menuItemIcon">Loading your apps...</slot> {{Name}} 
    </span>  
    <span class="fa-nav-close">
        <span class="fas fa-times"></span> Close
    </span>
    <nav :class="['overlayOne', isShow?'nowOn':'']">
      <div>
        <slot name="menuContentIcon"></slot> {{Name.toUpperCase()}}
        <div class="nav-link-wrapper">
          <a v-for="item in modules" :href="item.href" v-bind:key="item.href">
            {{item.display}}
          </a>
          <a v-if="errorFetchingModules" href="#">Error fetching your apps.</a>
          <span v-if="modulesLoading" class="loading"><span style="margin-right: 5px" class="fa fa-spinner fa-spin"></span>&#160;Loading your apps...</span>
        </div>      
      </div>
    </nav>
  </a>
</template>

<script>
import menuItemsMixin from "../menuItemsMixin.js"

export default {
  name: 'DesktopMenuItem',
  props: {
    Name: String,
    ClassName: Array,
    ContentTitle: String,
    CurrentMenuItem: String
  },
  mixins: [
    menuItemsMixin
  ],
  watch: {
      CurrentMenuItem: function(val) {
          if (this.Name == val) {
            this.fetchMenuItems(this.Name);
          }
      }
  },
  methods: {
    menuClick: function () {  
      this.$parent.$emit('menuItemClicked', this.Name);
    }
  },
  computed: {
    isShow() { return this.Name == this.CurrentMenuItem }, 
    navClass() {
      var classes = this.ClassName;
      if (this.isShow) {
        classes.push('openNav');
      }
      else {
        classes = classes.filter(function(value){ 
          return value != 'openNav';
        });
      }
      return classes;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .loading {
    color: white;
    display: flex;
  }
</style>
