<template>
    <span>
      <a @click="menuClick">{{Name}} <span class="fa fa-chevron-right"></span></a>
      <div :class="['dmrcMobileSubMenu', 'navigationContainer', isShow?'flipOn':'']">
        <nav>
          <span class="levelUp far fa-arrow-circle-left" @click="menuClick"></span>
          <span class="corporate-links">
            <span class="textSpan">{{Name.toUpperCase()}}</span>
            <a v-for="item in modules" :href="item.href" v-bind:key="item.href">
              {{item.display}}
            </a>
            <a v-if="errorFetchingModules" href="#">Error fetching your apps.</a>
            <span v-if="modulesLoading" class="loading"><span class="fa fa-spinner fa-spin"></span>&#160;Loading your apps...</span>
          </span>
        </nav>
      </div>
    </span>
</template>

<script>
import menuItemsMixin from "../menuItemsMixin.js"

export default {
  name: 'MobileMenuItem',
  props: {
    Name: String,
    CurrentMenuItem: String
  },
  mixins: [
    menuItemsMixin
  ],
  watch: {
      CurrentMenuItem: function(val) {
          if (this.Name == val) {
            this.fetchMenuItems(this.Name);
          }
      }
  },
  data: function () {
    return {
      isOpen: false
    };
  },
  methods: {
    menuClick: function () {  
      this.$parent.$emit('menuItemClicked', this.Name);
    }
  },
  computed: {
    isShow() { return this.Name == this.CurrentMenuItem  }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .loading {
    color: white;
  }
</style>
