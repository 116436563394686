<template>
    <!-- Mobile navigation -->
    <header class="mobileNav">
      <nav>     
        <a style="width: 170px;" href="/" class="mobilNav-logo">
              <svg style="width:170px;height:auto;" version="1.1" id="Layer_1" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="206 294.3 1424.3 199.2" enable-background="new 206 294.3 1424.3 199.2" xml:space="preserve" class="full-digimarc-logo">
                <g>
                  <g>
                    <rect x="359.1" y="352.1" fill="#326295" width="22" height="90.2"></rect>
                    <rect x="584.4" y="352.1" fill="#326295" width="22" height="90.2"></rect>
                    <polygon fill="#326295" points="659.4,351.9 677.1,351.9 721.3,405.5 765.2,351.9 783,351.9 783,442.2 762.5,442.2 762.5,380.1 
                      722.1,431.3 717.9,431.3 677.1,381.6 677.1,442.2 659.4,442.2     "></polygon>
                    <path fill="#326295" d="M863.9,405.9l20.3-37.6l21.1,37.6H863.9z M821.2,442.2h22l11.3-19.8h59.7l11.3,19.8h24.8l-54.4-90.2h-21.1
                      L821.2,442.2z"></path>
                    <path fill="#326295" d="M1057.5,367.5c6.6,0,10,1.3,10,7.3v10.5c0,6-3.4,7.3-10,7.3h-50.1v-25H1057.5z M985.6,442.2h21.8V408h20.7
                      l37.8,34.1h31.6l-42.9-34.1h12.4c14.5,0,21.1-4.9,21.1-17.3v-21.3c0-12.6-6.6-17.5-21.1-17.5h-81.3V442.2z"></path>
                    <path fill="#326295" d="M228.2,425.8v-57.2H267c20.9,0,31.3,8.7,31.3,27.5c0,23.5-13.9,29.7-41.8,29.7H228.2z M206,442.6h55.2
                      c39.7,0,59.7-14.9,59.7-45.5c0-27.9-18.5-44.8-54.2-44.8H206V442.6z"></path>
                    <path fill="#326295" d="M515.4,426.5h-38.8c-20.9,0-31.4-8.8-31.4-27.5c0-23.5,13.9-29.6,41.9-29.6h48.6v-16.9h-53.3
                      c-39.7,0-59.7,14.9-59.7,45.4c0,28,18.6,44.8,54.1,44.8h58.9v-50.4h-20.2h-24v17.9h24.5L515.4,426.5z"></path>
                    <path fill="#326295" d="M1229.7,426h-50.3c-20.9,0-31.3-8.8-31.3-27.5c0-23.5,13.9-29.7,41.8-29.7h39.8V352h-44.5
                      c-39.7,0-59.7,14.9-59.7,45.4c0,27.9,18.5,44.8,54.2,44.8h50V426z"></path>
                  </g>
                  <g>
                    <g>
                      <path fill="#326295" d="M1537.4,340.2h-18.1v20.6h-16.6c-10.2,0-18,5-18,11.5v61.6c0,6.5,7.9,11.5,18,11.5h35.4V340.2H1537.4z
                         M1519.3,373 M1519.3,433.1h-15.9V373h15.9V433.1z"></path>
                    </g>
                    <g>
                      <path fill="#326295" d="M1523.9,294.3h-2.5l0,22.2h-3.2c-59.1,0-107.1,39.7-107.1,88.5c0,48.8,48.3,88.5,107.6,88.5h2.6l0.1-22.1
                        l2.4,0c58.8-0.2,106.6-39.9,106.6-88.5C1630.3,334.2,1582.6,294.5,1523.9,294.3 M1521.3,468.4L1521.3,468.4c-0.6,0-1.2,0-1.8,0
                        c-48.4-1.9-86.5-34.6-86.5-74.4c0-40.1,38.5-72.8,86.7-74.5c0.6,0,1.2-0.1,1.7-0.1l0,0c49,1,88.2,33.9,88.2,74.5
                        C1609.7,434.5,1570.3,467.5,1521.3,468.4"></path>
                    </g>
                  </g>
                  <path fill="#326295" d="M1330.5,477.3c0,1.9-1.8,3.4-4,3.4l0,0c-2.2,0-4-1.5-4-3.4V314.2c0-1.9,1.8-3.5,4-3.5l0,0
                c2.2,0,4,1.6,4,3.5V477.3z"></path>
              </g>
            </svg>
        <!-- Circle D -->
<svg style="width:24px;height:24px;" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 200 200" xml:space="preserve" class="circle-D-logo"><g><g><path class="st0" fill="#326295" d="M115.1,49.3H98.8v18.6h-15c-9.2,0-16.3,4.5-16.3,10.4v55.6c0,5.8,7.1,10.4,16.3,10.4h31.9v-95H115.1zM98.8,133.2H84.4V79h14.3L98.8,133.2L98.8,133.2z"></path></g><g><path class="st0" fill="#326295" d="M102.9,8h-2.3v20h-2.9C44.4,28,1.1,63.8,1.1,107.8s43.5,79.9,97,79.9h2.3l0.1-20h2.2c53.1-0.2,96.2-36,96.2-79.9C198.9,44,155.8,8.1,102.9,8 M100.5,165L100.5,165c-0.5,0-1.1,0-1.6,0c-43.6-1.7-78-31.2-78-67.1c0-36.2,34.7-65.6,78.2-67.2c0.5,0,1.1,0,1.5,0l0,0c44.2,0.9,79.6,30.6,79.6,67.2C180.3,134.4,144.7,164.2,100.5,165"></path></g></g>
          </svg>
        </a>       
        <a :class="['mobileMenu', isOpen?'navOpen':'']" v-on:click="toggle">
          <span class="fa fa-bars"></span>
          <span class="fas fa-times"></span>
        </a>
      </nav>

      <div :class="['dmrcMobileMenu', 'navigationContainer', isOpen?'flipOn':'']">
        <nav id="mobileNavLevel1">
          <span class="levelUp far fa-arrow-circle-left" v-on:click="toggle"></span>
          <mobile-menu-item  
            :Name="'Corporate'" :CurrentMenuItem="currentMenuItem" >
          </mobile-menu-item>
          <mobile-menu-item 
            :Name="'Modules'"  :CurrentMenuItem="currentMenuItem">          
          </mobile-menu-item>
          <mobile-menu-item 
            :Name="'Support'" :CurrentMenuItem="currentMenuItem">
          </mobile-menu-item>
        </nav>
      </div>    
    </header>
</template>

<script>
import MobileMenuItem from './MobileMenuItem.vue'

export default {
  name: 'MobileMenu',
  components: {
    MobileMenuItem
  },
  data: function() {
    return {
      currentMenuItem: null,
      isOpen: false
    }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    }
  },
  created: function () {
    this.$on('menuItemClicked', (data) => {
      if (this.currentMenuItem == data) {
        this.currentMenuItem = null; // Already open, close it
      }
      else {
        this.currentMenuItem = data
      }
    })
  },
}
</script>